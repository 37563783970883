@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-consig {
  background-color: #ab0808;
}

.rdt_TableCell {
  @apply text-base-content;
}

.rdt_TableRow div:last-child,
.rdt_TableHeadRow div:last-child {
  border-right: 0 !important;
}

.rdt_TableCol,
.rdt_TableCell,
.rdt_TableHeadRow,
.rdt_Pagination {
  /* background-color: #272e48 !important; */
  /* border-bottom-color: rgb(54, 68, 93); */
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-collapse: collapse;
  /* border-left-color: rgb(54, 68, 93); */
  border-left-style: solid;
  border-left-width: 0px;
  /* border-right-color: rgb(54, 68, 93); */
  border-right-style: solid;
  border-right-width: 1px;
  border-spacing: 0px 0px;
  /* border-top-color: rgb(54, 68, 93); */
  border-top-style: solid;
  border-top-width: 1px;
  @apply border-gray-700 !bg-base-300;
}

.rdt_Pagination {
  @apply !text-base-content;
}

.rdt_TableHeadRow {
  @apply text-base-content;
  border-bottom: none !important;
}

.rdt_TableRow {
  @apply !bg-slate-700;
}

.rdt_TableRow:not(:last-of-type) {
  border-bottom-style: unset !important;
}

.rdt_TableHeadRow,
.rdt_Pagination {
  border-right: none;
}

.rdt_TableCol {
  border-bottom-width: 2px;
}

.rdt_Pagination {
  border-bottom: none;
}

.account-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#pagination-next-page svg,
#pagination-last-page svg,
#pagination-first-page svg,
#pagination-previous-page svg {
  @apply fill-base-content;
}

:root {
  --light: #edf2f9;
  --dark: #152e4d;
  --darker: #12263f;

  --base-pastel-blue: #7c7d81;

  --dark-eerie-black: #14171c;
  --dark-blue-cola: #0487d9;
  --dark-charcoal: #2c2d31;
  --dark-davys-grey: #7e7e7e;
  --dark-raisin-black: #222327;
  --dark-dark-gunmetal: #090e14;
  --dark-grape: #7d3bbf;

  --color-primary: var(--color-cyan);
  --color-primary-50: var(--color-cyan-50);
  --color-primary-100: var(--color-cyan-100);
  --color-primary-light: var(--color-cyan-light);
  --color-primary-lighter: var(--color-cyan-lighter);
  --color-primary-dark: var(--color-cyan-dark);
  --color-primary-darker: var(--color-cyan-darker);
  /*  */
  --color-green: #16a34a;
  --color-green-50: #f0fdf4;
  --color-green-100: #dcfce7;
  --color-green-light: #22c55e;
  --color-green-lighter: #4ade80;
  --color-green-dark: #15803d;
  --color-green-darker: #166534;
  /*  */
  --color-blue: #2563eb;
  --color-blue-50: #eff6ff;
  --color-blue-100: #dbeafe;
  --color-blue-light: #3b82f6;
  --color-blue-lighter: #60a5fa;
  --color-blue-dark: #1d4ed8;
  --color-blue-darker: #1e40af;
  /*  */
  --color-cyan: #0891b2;
  --color-cyan-50: #ecfeff;
  --color-cyan-100: #cffafe;
  --color-cyan-light: #06b6d4;
  --color-cyan-lighter: #22d3ee;
  --color-cyan-dark: #0e7490;
  --color-cyan-darker: #155e75;
  /*  */
  --color-teal: #0d9488;
  --color-teal-50: #f0fdfa;
  --color-teal-100: #ccfbf1;
  --color-teal-light: #14b8a6;
  --color-teal-lighter: #2dd4bf;
  --color-teal-dark: #0f766e;
  --color-teal-darker: #115e59;
  /*  */
  --color-fuchsia: #c026d3;
  --color-fuchsia-50: #fdf4ff;
  --color-fuchsia-100: #fae8ff;
  --color-fuchsia-light: #d946ef;
  --color-fuchsia-lighter: #e879f9;
  --color-fuchsia-dark: #a21caf;
  --color-fuchsia-darker: #86198f;
  /*  */
  --color-violet: #7c3aed;
  --color-violet-50: #f5f3ff;
  --color-violet-100: #ede9fe;
  --color-violet-light: #8b5cf6;
  --color-violet-lighter: #a78bfa;
  --color-violet-dark: #6d28d9;
  --color-violet-darker: #5b21b6;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .toggle:checked + span {
    @apply top-0 left-6 bg-primary;
  }
  .toggle:disabled + span {
    @apply bg-gray-500 shadow-none;
  }
  .toggle:checked:disabled {
    @apply bg-gray-200;
  }
  .toggle:checked:disabled + span {
    @apply shadow-none bg-primary-lighter;
  }
  .toggle:focus + span {
    @apply ring ring-primary-lighter;
  }

  input:invalid.invalid,
  textarea:invalid.invalid {
    @apply ring ring-danger-light dark:ring-danger;
  }
}

.main-content {
  background-color: var(--main-content-background);
}

.left-menu {
  background-color: var(--leftmenu-background);
}

.top-bar {
  background-color: var(--topbar-background);
}
